cs.ns('app.ui.root');
app.ui.root.view = cs.clazz({
    extend:   app.fw.root.view,
    mixin:    [
        app.ui.trait.root.hash.view,
        app.ui.trait.root.modaldialogs.view
    ],
    dynamics: {
        markupName:     'appRoot',
        container:      null,
        splash:         null,
        useDefaultPlug: false
    },
    protos:   {

        render () {
            this.base()
            this.ui.appendTo(this.container)
            this.splash.hide()
        },

        release () {
            this.base()
            this.ui.detach()
        },

        prepareMaskReferences () {
            this.base();
            this.container = $('body')
            this.splash    = $(".splash", this.container)
        }

    }
})